// src/components/NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Your requested Page not found.</p>
    </div>
  );
};

export default NotFoundPage;


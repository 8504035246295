import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';



import axios from 'axios';
import './LoginPage.css'; // Importing stylesheet


const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate
  const [userData, setUserData] = useState(null); // State to store user data

// Handle user login
const handleLogin = async (e) => {
  e.preventDefault();
  setError('');
  
  const requestData = JSON.stringify({
    email,
    password
  });

  try {
    const response = await axios.post('https://api.infradelta.com/auth/login', {
      body: requestData
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // Parse the response body (since it's returned as a JSON string)
    const responseData = JSON.parse(response.data.body);
    
    if (response.data.statusCode === 200 && responseData.message === "Login successful") {
      // Redirect to the dashboard on successful login
      navigate('/dashboard');
    } else {
      // Set an error message if login failed
      setError(responseData.message || "Login failed.");
    }
  } catch (error) {
    // Handle any errors during the axios call
    console.error("Login error:", error);
    setError('Login failed. Please check your credentials.');
  }
};

  const handleRegisterRedirect = () => {
    navigate('/register');
  };

  const handleGoogleSuccess = (response) => {
    const token = response.credential;
    const decoded = jwtDecode(token);


    // Log decoded token (contains user info)
    console.log('Decoded JWT:', decoded);

    // You can access user info here
    const { email, given_name: firstName, family_name: lastName } = decoded;

    // Save the user info to state
    setUserData({
      firstName,
      lastName,
      email,
    });

    setError(''); // Clear any previous error
  };

  // Handle Google Sign-In failure
  const handleGoogleFailure = (error) => {
    setError('Google login failed. Please try again.');
    console.error('Google login error:', error);
  };


  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>      <button onClick={handleRegisterRedirect}>Register</button>

      </form>
      {error && <p className="error-message">{error}</p>}

      {/* Google OAuth Login */}
      <GoogleOAuthProvider clientId="301510546215-b89i4spqa724cg9ihsrldluj1o3q5gpk.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
        />
      </GoogleOAuthProvider>

      {/* Display User Info after Successful Sign-In */}
      {userData && (
        <div className="user-info">
          <h3>Welcome, {userData.firstName} {userData.lastName}</h3>
          <p>Email: {userData.email}</p>
        </div>
      )}

      {/* Display error if Google login fails */}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default LoginPage;

